/* eslint-disable no-unused-vars */
import callEndpoint from "@/services/api";
import METHODS from "@/services/api/apiMethods";
import axios from "axios";
import authHeader from "@/services/api/auth-header";
import { data } from "autoprefixer";

let RESOURCE_URL = "";

if (process.env.NODE_ENV === "development") {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
} else {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
}
const {
  POST,
  //     PUT,
  PATCH,
  DELETE,
} = METHODS;

export const uploadToCloudinary = async (data) => {

  return axios.post(process.env.VUE_APP_CLOUDINARY_URL, data);
};
// https://res.cloudinary.com/demo/image/upload/f_auto,q_auto,e_trim,b_white/bo_1px_solid_gray/docs/rmv_bgd/stuffed.jpg
// Get countries and their states
const COUNTRY_BASE_URL = "https://countriesnow.space/api/v0.1/countries/states";

const CITY_BASE_URL =
  "https://countriesnow.space/api/v0.1/countries/state/cities";

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};


export const getAllCountries = async () => {
  return axios.get(COUNTRY_BASE_URL,{headers:headers});
};
export const getAllStatesByCountry = async (country) => {
  return axios.post(COUNTRY_BASE_URL, country,{headers:headers});
};

export const getAllCitiesInAState = async (data) => {
  return axios.post(CITY_BASE_URL, data,{headers:headers});
};



export const updatePassword = async (data) =>{
  const url = `/api/user/change-password/${data.email}`
  return callEndpoint({method:PATCH, url,data})
}

export const getGuardianById = async (userId) =>{
  const url = `/api/guardian/get-by-user/${userId}`
  return callEndpoint({url})
}

export const updateGuardianById = async (data) =>{
  const url = `/api/guardian/onboard`
  return callEndpoint({method:PATCH,url,data})
}

export const updateGuardian = async (data) =>{
  const url = `/api/guardian/update/${data.guardianId}`
  return callEndpoint({method:PATCH,url,data})
}

export const getTotalApplicants = async () =>{
  const url = `/api/event-application/total-applications`
  return callEndpoint({url})
}

export const getTotalAcceptedApplicants = async ()=>{
  const url = `/api/event-application/get/total-accepted`
  return callEndpoint({url})
}

export const getTotalVolunteers = async () =>{
  const url = `/api/volunteer/get/total-volunteers`
  return callEndpoint({url})
}

export const getTotalInstructors = async  () =>{
  const url = `/api/instructor/get-total-instructors`
  return callEndpoint({url})
}

export const  getTotalGuardians = async () =>{
  const  url = `/api/guardians/get-total-guardians`
  return callEndpoint({url})
}

export const getAllInstructors = async () =>{
  const url = `/api/instructor/get-all`
  return callEndpoint({url})
}

export const guardianAddCandidate = async (data) =>{
  const url = `/api/player/new-player/${data.guardianId}`
  return callEndpoint({method:POST,url,data})
}

export const getAllGuardianCandidate = async (guardianId) =>{
  const url = `/api/player/get-all/${guardianId}`
  return callEndpoint({url})
}

export const addVolunteer = async (data) =>{
  const url =`/api/volunteer/signup`
  return callEndpoint({method:POST,url,data})
}

export const subscribe = async (data) =>{
  const url = `/api/subscriber/subscribe`
  return callEndpoint({method:POST,url,data})
}

export const contactUs = async (data) =>{
  const url = '/api/contact/contact-b4b'
  return callEndpoint({method:POST,url,data})
}

export const guardianVolunteer = async (guardianId) =>{
  const  url = `/api/volunteer/guardian-apply-as-volunteer/${guardianId}`
  return callEndpoint({method:POST,url})
}

export const getAllUpcomingEvents = async ()=>{
  const url = `/api/event/get-upcoming-events`
  return callEndpoint({url})
}

export const applyToEvent = async  (data) =>{
  const url = `/api/event-application/new-application/${data.guardianId}`
  return callEndpoint({method:POST,url,data})
}